import React from 'react';
import { Col, Form, Input, Row } from 'antd';
import MainView from '../../components/View/MainView';
import { EntitiesSearch } from '../../components/entitiesSearch';
import { usePageTitle } from '../../hooks/usePageTitle';
import { Locale } from '../../../localization/LocalizationKeys';
import { usePersonTableQuickActions } from './hooks/usePersonTableQuickActions';
import { EntityTypeEnum } from '../../../gql/typings';
import EstherEasterEgg from '../../components/EasterEggs/EstherEasterEgg';
import { useUser } from '../../util/useUser';
import { useLocalization } from '../../util/useLocalization';
import { useSystemPermission } from '../../permission/useSystemPermission';

const PersonView = () => {
  const localization = useLocalization();
  const headerStr = localization.formatMessage(Locale.General.Persons_HCP);
  usePageTitle(headerStr);
  const [form] = Form.useForm();
  const { entitiesSearchProps, tableSelection, HiddenRender } = usePersonTableQuickActions(form, { maintainPageState: true });
  const { username } = useUser();
  const hasGlobalSearchPermission = useSystemPermission('globalSearch');

  return (
    <MainView className="person-view-container" boundingBox={false}>
      {username.slice(-14) === '@apurebase.com' && (
        <EstherEasterEgg
          text={['Launching', 'PureAdvance MDM', '2023 Hooray!!']}
        />
      )}
      <EntitiesSearch
        entityType={EntityTypeEnum.PERSON}
        form={form}
        globalSupport
        globalSearch={hasGlobalSearchPermission}
        urlSearchEnabled
        tableSelection={tableSelection}
        customSearchFilterCriteria={<>

          <Input.Group className="criteria-input-container contrast-label" style={{ display: 'grid', maxWidth: '220px' }}>
            <span className="label">
              {localization.formatMessage(Locale.Attribute.aPureBase_ID)}
            </span>
            <Row gutter={[4, 4]}>
              <Col span={24}>
                <Form.Item name='aPureBaseIds'>
                  <Input
                    id="aPureBaseIds"
                    size='large'
                  />
                </Form.Item>
              </Col>
            </Row>
          </Input.Group>

        </>}
        {...entitiesSearchProps}
      />
      <HiddenRender />
    </MainView>
  );
};

export default PersonView;

