import { pickBy, isEmpty, startsWith } from 'lodash';
import { criteriasToArrayFactory } from '../criterias/criteriasToArrayFactory';
import { criteriaInputKey } from '../../../components/CriteriaInput/CriteriaInputFactory';
import { BuiltCriteria, CriteriaBuilderInput } from '../../../util/criteriaBuilderSelector';


export default (props: CriteriaBuilderInput): BuiltCriteria => {
  const criteria: BuiltCriteria = {
    criteria: {
      aPureBaseIds: undefined,
      criterias: undefined,
      isActive: props.isActive,
      fetchSize: {
        limit: props.perPage ?? 20,
        offset: ((props.page ?? 1) - 1) * (props.perPage ?? 20),
      },
    },
  };

  if (props.searchFilter.aPureBaseIds) criteria.criteria.aPureBaseIds = props.searchFilter.aPureBaseIds.toString().split(' ');

  if (props.orderingNew && props.orderingNew.length > 0) criteria.criteria.ordering = props.orderingNew;

  if (props.hiddenFilter) {
    criteria.criteria = { ...criteria.criteria, ...props.hiddenFilter };
  }

  if (props.countries && props.countries.length > 0) {
    criteria.criteria.countries = props.countries;
  }

  if (props.includeUnplaced) criteria.criteria.includeUnplaced = true;
  if (props.includeOptedOut) criteria.criteria.includeOptedOut = true;

  if (props.searchGlobal || (props.searchFilter && props.searchFilter['global-search'])) {
    criteria.criteria.searchGlobal = true;
  }

  const criterias = props.searchFilter && pickBy(props.searchFilter, (_, key) => (
    key.length > criteriaInputKey.length
    && startsWith(key, criteriaInputKey)
  ));

  if (!isEmpty(criterias)) { // TODO: Why is @ts-ignore here?? It should be removed!
    // @ts-ignore
    criteria.criteria.criterias = criteriasToArrayFactory({
      filterOutEmpties: typeof props.filterOutEmpties === 'boolean' ? props.filterOutEmpties : true,
      // @ts-ignore
    })(criterias);
  }

  return criteria;
};
